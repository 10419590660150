<template>
  <!-- 新增修改弹出框 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1000px"
    :showFooter="false"
    :title="dialogTitle"
    class="dialog"
  >
    <div class="addProcess">
      <base-form
        :componentList="addfrom"
        :formAttrs="{
          model: addqueryParas,
          labelWidth: '140px',
        }"
        class="formStyle"
        :showBtns="false"
        ref="addfromData"
      >
      </base-form>
    </div>
    <template slot="footer">
      <base-button label="提 交" @click="submit"></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="
          () => {
            this.currentVisible = false;
          }
        "
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import { businessManageApi } from '@/api/businessApi'
import baseForm from '@/components/common/base-form/base-form.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { baseForm, BaseDialog, BaseButton },
  name: 'addEditDialog',
  props: {
    addqueryParas: Object,
    submitType: String,
    dialogTitle: String,
    visible: Boolean,
    addfrom: Array
  },
  data () {
    return {}
  },
  computed: {
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    currentVisible () {
      this.$nextTick(() => {
        this.$refs.addfromData.clearValidate()
      })
    }
  },
  methods: {
    // 提交新增修改
    submit () {
      console.log(this.addqueryParas)
      if (this.addqueryParas.capitalSideName) {
        // 删除资金方 间隔 -
        this.addqueryParas.capitalSideName = this.addqueryParas.capitalSideName.replace('-', '')
      }
      this.$refs.addfromData.validate(valid => {
        if (valid) {
          if (this.submitType === 'add') {
            // 业务编号生成
            businessManageApi.business(this.addqueryParas).then(res => {
              if (res.success) {
                this.$emit('handleFilter')
                this.success('新增成功')
                this.currentVisible = false
              }
            })
          } else if (this.submitType === 'updata') {
            businessManageApi.business(this.addqueryParas).then(res => {
              if (res.success) {
                this.$emit('handleFilter')
                this.success('修改成功')
                this.currentVisible = false
              }
            })
          }
        }
      })
    }
  }
}
</script>
